import gql from 'graphql-tag';
import { useMutation } from '@apollo/client';
import { faQuestionCircle } from '@fortawesome/free-regular-svg-icons/faQuestionCircle';
import { Button, Divider, Dropdown, FlexGrid, Header, Modal, Text, Tooltip } from '@gasbuddy/react-components';
import classnames from 'classnames/bind';
import PropTypes from 'prop-types';
import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import useAnalytics from '../../../lib/hooks/useAnalytics';
import useProfile from '../../../lib/hooks/useProfile';
import { generateTimeSpottedList, noop, pluralizeIfNeeded, uniqueReducer } from '../../../lib/utils';
import { COUNTRY_CODES, FUEL_POI_VALUE_TO_NAME } from '../../constants';
import ANALYTICS_EVENTS from '../../constants/analyticsEvents';
import StationPropTypes from '../../prop-types/station';
import EditMemberNameModal from '../EditMemberNameModal';
import GasPriceEditor from '../GasPriceEditor';
import StationInfoBox from '../StationInfoBox';
import ThankYou from '../ThankYou';
import styles from './StationPriceReport.module.css';
import useMemberNameModalAvailability from '../../../lib/hooks/useMemberNameModalAvailability';

const cx = classnames.bind(styles);

export const SUBMIT_PRICE_REPORT = gql`
  mutation SubmitPriceReport($stationId: String!, $report: PriceReportInput!, $timeStamp: String!, $recaptchaResponse: String!) {
    submitPriceReport(stationId: $stationId, report: $report, timeStamp: $timeStamp, recaptchaResponse: $recaptchaResponse) {
      creditsAwarded
      error {
        code
        message
      }
    }
  }
`;

export default function StationPriceReport({
  hidePriceReportButton,
  modalOpen,
  onCancel,
  onReportDone,
  station,
}) {
  const TIME_SPOTTED_LIST = useMemo(() => generateTimeSpottedList(), []);
  const { isLoggedIn, memberName, canSetMemberName } = useProfile();
  const [pricesPosted, setPricesPosted] = useState(false);
  const [showMemberNameCustomization, setShowMemberNameCustomization] = useState(false);
  const [showPriceReportModal, setShowPriceReportModal] = useState(false);
  const [pricesConfirmed, setPricesConfirmed] = useState({ cash: {}, credit: {} });
  const [priceWarnings, setPriceWarnings] = useState({ cash: {}, credit: {} });
  const [timeSpotted, setTimeSpotted] = useState(TIME_SPOTTED_LIST[0].value);
  const [recaptchaAttempted, setRecaptchaAttempted] = useState(false);
  const [recaptchaToken, setRecaptchaToken] = useState();
  const analytics = useAnalytics();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [shouldShowMemberNameModal, setLastShownMemberNameModal] = useMemberNameModalAvailability();

  const { address, amenities, fuels, id: stationId, name: stationName, prices } = station;

  const existingPrices = useMemo(() => {
    const existingPriceObj = { cash: {}, credit: {} };
    prices.forEach((priceReport) => {
      existingPriceObj.cash[priceReport.fuelProduct] = priceReport.cash?.price;
      existingPriceObj.credit[priceReport.fuelProduct] = priceReport.credit?.price;
    });

    return existingPriceObj;
  }, [prices]);

  const isUSStation = useMemo(() => address.country === COUNTRY_CODES.USA, [address.country]);
  const stationHasCashPrices = useMemo(() => amenities?.some(amenity => amenity.amenityId === 'cash_credit'), [amenities]);

  const [
    submitPriceReport,
    { data: priceSubmission, loading: isPosting, reset },
  ] = useMutation(SUBMIT_PRICE_REPORT, {
    onCompleted: () => {
      setPricesPosted(true);
    },
  });
  const { creditsAwarded, error: postError } = priceSubmission?.submitPriceReport || {};
  const showThankYouModal = pricesPosted && !postError;

  const onOpenPriceReportModal = useCallback(() => {
    setShowMemberNameCustomization(false);
    setShowPriceReportModal(true);
    analytics.tagEvent({
      name: ANALYTICS_EVENTS.PRICE_REPORT_MODAL_OPENED,
      attributes: {
        Station_ID: stationId,
        Station_Name: stationName,
      },
    });
  }, [analytics, stationId, stationName]);

  const openModal = useCallback(() => {
    if (canSetMemberName && shouldShowMemberNameModal) {
      setShowMemberNameCustomization(true);
      setLastShownMemberNameModal(new Date());
      analytics.tagEvent({
        name: ANALYTICS_EVENTS.RECEIVED_EDIT_USERNAME_PROMPT,
        attributes: { Context: 'Price_Report' },
      });
    } else {
      onOpenPriceReportModal();
    }
  }, [analytics, canSetMemberName, onOpenPriceReportModal, setLastShownMemberNameModal, shouldShowMemberNameModal]);

  useEffect(() => {
    // Check if we need to open the modal flow or if it is already open
    if (modalOpen && !showMemberNameCustomization && !showPriceReportModal) {
      openModal();
    }
  }, [modalOpen, openModal, showMemberNameCustomization, showPriceReportModal]);

  const closeModal = useCallback(() => {
    if (pricesPosted) {
      onReportDone();
    } else {
      analytics.tagEvent({
        name: ANALYTICS_EVENTS.PRICE_REPORT_MODAL_ABANDONED,
        attributes: { Station_ID: stationId },
      });
      onCancel();
    }

    setPricesConfirmed({ cash: {}, credit: {} });
    setPriceWarnings({ cash: {}, credit: {} });
    setShowMemberNameCustomization(false);
    setShowPriceReportModal(false);
    setPricesPosted(false);
    setRecaptchaAttempted(false);
    setRecaptchaToken(null);
    reset();
  }, [analytics, onCancel, onReportDone, pricesPosted, reset, stationId]);

  const handleTimeSpottedChanged = useCallback(({ target }) => {
    setTimeSpotted(target.value);
  }, []);

  const handlePricesSubmitted = useCallback(async () => {
    const responseToken = await executeRecaptcha('priceReport');
    setRecaptchaToken(responseToken);
    setRecaptchaAttempted(true);

    if (responseToken) {
      const typesReported = prices.map(priceReport => priceReport.longName).reduce(uniqueReducer, []);

      const typesConfirmed = Object.entries(pricesConfirmed)
        .map(([, v]) => Object.keys(v))
        .reduce((types, type) => types.concat(type), [])
        .reduce(uniqueReducer, []);

      analytics.tagEvent({
        name: ANALYTICS_EVENTS.PRICE_REPORT_SUBMITTED,
        attributes: {
          Station_ID: stationId,
          Reported_Fuel_Products: typesReported.join(','),
          Confirmed_Fuel_Products: typesConfirmed.join(','),
        },
      });

      submitPriceReport({
        variables: {
          stationId,
          report: pricesConfirmed,
          timeStamp: timeSpotted,
          recaptchaResponse: responseToken,
        },
      });
    }
  }, [analytics, executeRecaptcha, prices, pricesConfirmed, stationId, submitPriceReport, timeSpotted]);

  const updateConfirmedPrices = useCallback((fuelType, priceType, value) => {
    const newPriceObject = { ...pricesConfirmed };
    const newPrice = parseFloat(value);

    if (Number.isNaN(newPrice)) {
      delete newPriceObject[priceType][fuelType];
    } else {
      newPriceObject[priceType][fuelType] = newPrice;
    }

    setPricesConfirmed(newPriceObject);
  }, [pricesConfirmed]);

  const updateWarnings = useCallback((fuelType, priceType, value) => {
    // Check for potential bad prices
    const oldPrice = existingPrices[priceType][fuelType];
    if (oldPrice) {
      const priceDifference = parseFloat(value) - oldPrice;
      const newPriceWarnings = { ...priceWarnings };
      if (Math.abs(priceDifference) >= (isUSStation ? 0.4 : 40.0)) {
        newPriceWarnings[priceType][fuelType] = `This price seems ${priceDifference > 0 ? 'high' : 'low'}`;
      } else {
        delete newPriceWarnings[priceType][fuelType];
      }
      setPriceWarnings(newPriceWarnings);
    }
  }, [existingPrices, isUSStation, priceWarnings]);

  const onPriceConfirmed = useCallback((fuelType, priceType, value) => {
    updateConfirmedPrices(fuelType, priceType, value);
    updateWarnings(fuelType, priceType, value);
  }, [updateConfirmedPrices, updateWarnings]);

  const confirmedPricesCount = useMemo(() => {
    let confirmedPrices = 0;
    Object.entries(pricesConfirmed).forEach(([, value]) => {
      Object.entries(value).forEach(([, innerValue]) => {
        if (parseFloat(innerValue) > 0) {
          confirmedPrices += 1;
        }
      });
    });
    return confirmedPrices;
  }, [pricesConfirmed]);

  const hasPriceWarnings = useMemo(() => (
    Object.entries(priceWarnings).some(([, val]) => Object.entries(val).some(([, innerVal]) => innerVal))
  ), [priceWarnings]);

  const renderedModalActions = useMemo(() => {
    const thisText = pluralizeIfNeeded(confirmedPricesCount, 'this', 'ese', 2);
    const priceText = pluralizeIfNeeded(confirmedPricesCount, 'price', 's');
    const readyToSubmitText = `Submit ${confirmedPricesCount} ${priceText}`;
    const warningsText = `Submit ${thisText} ${priceText}`;
    return (
      <Fragment>
        {hasPriceWarnings && (
          <Text as="p" centered className={cx('warningMessage')} color="orange">
            {`Are you sure ${thisText} ${priceText} ${pluralizeIfNeeded(confirmedPricesCount, 'is', 'are', '2')} correct?`}
          </Text>
        )}
        <Button
          fluid
          primary
          warning={hasPriceWarnings}
          disabled={confirmedPricesCount === 0 || isPosting || !executeRecaptcha}
          loading={isPosting}
          onClick={handlePricesSubmitted}
        >
          {hasPriceWarnings ? warningsText : readyToSubmitText}
        </Button>
      </Fragment>
    );
  }, [confirmedPricesCount, executeRecaptcha, handlePricesSubmitted, hasPriceWarnings, isPosting]);

  function renderPriceEditor(priceReport = { cash: {}, credit: {} }, priceType, fuelType) {
    const defaultPrice = priceReport[priceType]?.price;
    const editorKey = `${fuelType}_${priceType}`;
    const confirmedFuelPriceValue = pricesConfirmed[priceType]?.[fuelType];
    const validatedDefaultValue = defaultPrice > 0 ? defaultPrice.toFixed(isUSStation ? 2 : 1) : '';
    const editorValue = defaultPrice === undefined ? confirmedFuelPriceValue : validatedDefaultValue;
    let pricePlaceholder = '00.0';
    if (!defaultPrice) {
      if (isUSStation) {
        pricePlaceholder = '0.00';
      }
    } else {
      pricePlaceholder = defaultPrice.toFixed(isUSStation ? 2 : 1);
    }

    return (
      <GasPriceEditor
        key={editorKey}
        error={priceWarnings[priceType][fuelType]}
        inputId={`${editorKey}_input`}
        isConfirmed={!!confirmedFuelPriceValue}
        isCash={priceType === 'cash'}
        isInDollars={isUSStation}
        onConfirm={(v) => { onPriceConfirmed(fuelType, priceType, v); }}
        placeholder={pricePlaceholder}
        showBadge={stationHasCashPrices}
        value={editorValue}
      />
    );
  }

  function renderFuel(priceReport, fuel) {
    const fuelName = FUEL_POI_VALUE_TO_NAME[fuel];
    return (
      <Fragment key={`${fuelName} section`}>
        <FlexGrid.Column className={cx('fuelHeaderContainer')}>
          <Header as="h3" className={cx('priceReportSubHeader')}>
            {fuelName}
          </Header>
        </FlexGrid.Column>
        <FlexGrid.Column
          className={cx('fuelTypeContainer')}
          tablet={stationHasCashPrices ? 6 : 12}
        >
          {renderPriceEditor(priceReport, 'credit', fuel)}
        </FlexGrid.Column>
        {stationHasCashPrices && (
          <FlexGrid.Column
            className={cx('fuelTypeContainer')}
            tablet={6}
          >
            {renderPriceEditor(priceReport, 'cash', fuel)}
          </FlexGrid.Column>
        )}
      </Fragment>
    );
  }

  function renderPriceGrid() {
    // it may be overkill to filter this, but these are the only fuel types we know will have prices for sure
    const filteredFuels = fuels.filter(fuel => Object.keys(FUEL_POI_VALUE_TO_NAME).includes(fuel));

    return (
      <Fragment>
        <StationInfoBox station={station} />
        <Divider className={cx('topDivider')} />
        <div className={cx('scrollableContent')}>
          <div className={cx({ centeredContent: !stationHasCashPrices })}>
            <div className={cx('timeSpottedContainer')}>
              <Header as="h3" className={cx('priceReportSubHeader')}>
                Time Spotted
                <Tooltip
                  bordered={false}
                  className={cx('tooltip')}
                  icon={faQuestionCircle}
                  content={
                    (
                      <Fragment>
                        <Text as="p" bold>What is &quot;Time Spotted&quot;?</Text>
                        <Text as="p">
                          Sometimes you won&rsquo;t be able to update a station&rsquo;s price right when you see it.
                          <br /><br />
                          We ask that you use Time Spotted to make sure &quot;old&quot; prices don&rsquo;t get submitted overtop of &quot;new&quot; prices.
                        </Text>
                      </Fragment>
                    )
                  }
                />
              </Header>
              <Dropdown
                className={cx('timeSpottedDropdown')}
                id="timeSpotted"
                inline
                label="Select time spotted"
                options={TIME_SPOTTED_LIST}
                onChange={handleTimeSpottedChanged}
                upward
                value={timeSpotted}
              />
            </div>
            <FlexGrid>
              {filteredFuels.map((fuel) => {
                const priceReport = prices.find(price => price.fuelProduct === fuel);
                return renderFuel(priceReport, fuel);
              })}
            </FlexGrid>
          </div>
        </div>
        <Divider className={cx('bottomDivider')} />
      </Fragment>
    );
  }
  const handleMemberNameChangeRequested = useCallback(() => {
    analytics.tagEvent({
      name: ANALYTICS_EVENTS.CHANGE_USERNAME_OPENED,
      attributes: { Context: 'Price_Report' },
    });
  }, [analytics]);

  const handleMemberNameUpdateFinish = useCallback(() => {
    analytics.tagEvent({
      name: ANALYTICS_EVENTS.USERNAME_UPDATED,
      attributes: { Context: 'Price_Report' },
    });
  }, [analytics]);

  if (!fuels?.length) {
    return null;
  }

  if (!isLoggedIn) {
    return (
      <Button
        className={cx('reportButton')}
        primary
        fluid
        as="a"
        href="/login"
      >
        Log In To Report Prices
      </Button>
    );
  }

  return (
    <Fragment>
      {!hidePriceReportButton && (
        <Button
          className={cx('reportButton')}
          primary
          fluid
          onClick={openModal}
        >
          Report Prices
        </Button>
      )}
      {showMemberNameCustomization && (
        <EditMemberNameModal
          memberName={memberName}
          messageType="price"
          onCloseModal={onOpenPriceReportModal}
          onMemberNameChangeRequested={handleMemberNameChangeRequested}
          onMemberNameUpdated={handleMemberNameUpdateFinish}
          showModal
        />
      )}
      {showPriceReportModal && (
        <Modal
          size={showThankYouModal ? 'sm' : 'md'}
          forceIsShowing
          onClose={closeModal}
          content={({ close }) => {
            if (showThankYouModal) {
              return (
                <ThankYou
                  creditsAwarded={creditsAwarded}
                  messageType="price"
                  onDone={close}
                />
              );
            }

            return (
              <Fragment>
                {renderPriceGrid()}
                {!!postError && (
                  <Text as="p" centered color="orange">
                    An error occurred while submitting your price report. Please try again later.
                  </Text>
                )}
                {recaptchaAttempted && !recaptchaToken && (
                  <Text as="p" centered color="orange">
                    Verification failed. Unable to submit price report at this time.
                  </Text>
                )}
                {renderedModalActions}
              </Fragment>
            );
          }}
        />
      )}
    </Fragment>
  );
}

StationPriceReport.propTypes = {
  /** Override for if you do not want to render the "Report Prices" button */
  hidePriceReportButton: PropTypes.bool,
  /** Programmatically force the price report modal to be open */
  modalOpen: PropTypes.bool,
  /** A method executed when the price report modal has been closed without posting prices */
  onCancel: PropTypes.func,
  /** A method executed once a price report has been posted and the modal has been closed */
  onReportDone: PropTypes.func,
  /** The station being updated */
  station: StationPropTypes.isRequired,
};

StationPriceReport.defaultProps = {
  hidePriceReportButton: false,
  modalOpen: false,
  onCancel: noop,
  onReportDone: noop,
};
